import React from "react"

import Layout from "src/layout"
import { Section, SectionTitle, Text } from "src/components/styled"

const NotFoundPage: React.FC = () => (
  <Layout title="Conditions Générales de Vente" noindex>
    <Section>
      <SectionTitle>Conditions Générales de Vente</SectionTitle>
      <Text>1. Introduction</Text>
      <p>
        La société Collectif Prémices, exploitante de la marque Ateliers Chutes Libres, propose des ateliers de
        construction de mobilier pour les particuliers et met tout en œuvre pour offrir aux clients la sécurité
        nécessaire à leur bon déroulement, notamment lors de l’utilisation de machines. Les ateliers impliquent la
        participation active du client qui accepte de se montrer vigilant et de se soumettre aux consignes de sécurité
        qui lui seront données par les encadrants des Ateliers Chutes Libres.
      </p>
      <p>
        De plus, la société Collectif Prémices ne peut être tenue responsable des accidents survenus lors des Ateliers
        Chutes Libres et déclare se dégager de toute responsabilité en cas d’accident de quelque nature que ce soit ou
        de dommages sans aucunes exceptions ni réserves.
      </p>
      <p>
        L’achat d’un ou plusieurs billet Ateliers Chutes Libres implique de la part du client l’acceptation sans réserve
        des présentes conditions générales de vente en vigueur au jour de l’inscription au cours, telles qu’elles sont
        mentionnées ci-dessus.
      </p>
      <Text>2. Inscription et paiement</Text>
      <p>
        L’inscription aux Ateliers Chutes Libres se fait via la plateforme d’achat en ligne du site web des Ateliers
        Chutes Libres, dans la limite des places disponibles. Un email de confirmation de votre réservation vous sera
        envoyé.
      </p>
      <p>Le prix de l’atelier est celui qui figure sur le site web au moment de l’achat.</p>
      <p>Les prix sont exprimés en euros TTC (la TVA applicable pour les cours est de 20%).</p>
      <Text>3. Annulation de l’inscription par le client</Text>
      <p>
        Si le client annule le cours réservé plus de 7 jours calendaires avant la date, le montant total du règlement
        sera conservé et pourra être utilisé sous forme d’avoir pour une nouvelle réservation dans les trois mois
        suivants la date de la réservation initiale, sous réserve de places disponibles.
      </p>
      <p>
        Si le client annule le cours moins de 7 jours calendaires avant la date, le montant total du règlement sera
        acquis à titre de dommages et intérêts.
      </p>
      <p>
        La société Collectif Prémices exploitante de la marque Ateliers Chutes Libres se réserve le droit d’annuler
        l’atelier réservé en cas de force majeure et une telle annulation ne pourra engager sa responsabilité. Dans ce
        cas, le règlement sera intégralement remboursé au client.
      </p>
      <Text>4. Responsabilité</Text>
      <p>
        Les Ateliers Chutes Libres impliquent la participation active du client. Ce dernier accepte de se montrer
        vigilant et de se soumettre aux consignes d’hygiène et de sécurité qui lui sont données par les encadrants pour
        le bon déroulement de l’atelier. Collectif Prémices ne pourra pas être tenu responsable si un dommage corporel
        intervient lors d’un atelier dont le participant serait victime. Collectif Prémices se réserve le droit
        d’exclure un participant (adulte ou enfant) dont le comportement lors de l’atelier nuirait à sa propre sécurité
        et à celle des autres, ceci restant à l’appréciation de l’encadrant en charge de l’animation de l’atelier. Lors
        des ateliers parents/enfants, le ou les parents sont responsables du comportement du ou des enfants qui les
        accompagnent. De plus, Collectif Prémices ne sera pas tenu responsable de tout dommage ou perte des objets ou
        effets personnels apportés par les participants. Collectif Prémices ne pourra être tenu responsable des dommages
        résultant de l’utilisation des objets ou mobiliers fabriqués pendant l’atelier.
      </p>
      <Text>5. Droit à l’image</Text>
      <p>
        Les participants autorisent le Collectif Prémices a reproduire ou représenter la ou les photographies les
        représentant, suivant la liste descriptive ci-après : <br />
        portraits divers non discriminatoire ne portant pas atteinte à la réputation ou la vie privée du sujet pour les
        usages suivants :
        <br />- Publication dans Toute revue, ouvrage ou journal dénommé
        <br />- Publication pour achat d’espace
        <br />- Présentation en public lors d’une exposition
        <br />- Présentation sur site web et réseaux sociaux des Ateliers Chutes Libres.
      </p>
      <Text>6. Propriété</Text>
      <p>
        Les objets et mobiliers fabriqués pendant l’atelier appartiennent au participant qui l’a construit. Le Collectif
        Prémices posséde les droits d’exploitation, de reproduction et de représentation du design et des photographies
        des objets réalisés pendant l’atelier.
      </p>
      <p>
        La reproduction, la représentation et la publication des objets et mobiliers présentés et fabriqués pendant les
        Ateliers Chutes Libres sont autorisées pour un usage exclusivement privé ou non commercial.
      </p>
      <Text>7. Résolution des litiges</Text>
      <p>
        Les présentes conditions sont régies par la loi française. Tout litige relatif à leur interprétation ou à leur
        application sera de la compétence du Tribunal de Commerce de Paris.
      </p>
    </Section>
  </Layout>
)

export default NotFoundPage
